.subscribe {
    padding: 5rem 0;
    background: #f8f8f8 url('../../assets/PNGs/subscribe-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 2px solid lighten(#ababab, 25);

    .subscribe-field {
        margin-right: 3rem;
    }

    @media screen and (max-width: 450px) {
        .subscribe-box {
            flex-direction: column;

            .subscribe-field {
                margin-bottom: 2rem;
                margin-right: 0;
            }
        }
    }
}