.product-gallery {
  .slick-slider {
    overflow: hidden;
    cursor: pointer;
    max-width: 600px;
  }

  .slick-slide,
  .slick-slide:focus * {
    outline: none !important;
  }

  button::-moz-focus-inner {
    border: 0;
  }

  *:focus {
    outline: 0;
    outline: none;
  }

  .product-thumbnails1 {
    margin-top: 30px;
    margin-right: -8px;

    &:hover {
      .slick-prev {
        visibility: visible;
      }

      .slick-next {
        visibility: visible;
      }
    }

    .slick-prev {
      left: 3% !important;
      z-index: 1;
      visibility: hidden;
    }

    .slick-next {
      right: 3% !important;
      z-index: 1;
      visibility: hidden;
    }

    .slick-arrow::before {
      color: #af6464;
    }

    .product-image {
      width: 95%;
    }

    .slick-current {
      .product-image {
        border: 1px solid #af6464;
        width: calc(95% - 1px);
      }
    }
  }
}
