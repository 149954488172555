@import '../../../dt-template/vars';

.mini-badge {
    position: absolute;
    top: -8px;
    right: -13px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: $active-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}