@import "../../dt-template/vars";

.backdrop {
  .close-icon {
    position: absolute;
    top: 10%;
    right: 10%;
    cursor: pointer;
  }

  .search-result {
    background-color: $color-white;
    color: #000000;
    max-height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 20px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d6dee1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d6dee1;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d6dee1;
      border-radius: 20px;
      border: 6px solid transparent;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a8bbbf;
    }
  }

  .product-row {
    width: 100%;
    height: 81px;

    &:hover {
      background-color: $color-secondary-light;
      cursor: pointer;
    }

    .product-content {
      display: flex;
      align-items: center;
      padding-left: 1rem;
      height: 100%;

      .product-details {
        margin-left: 0.8rem;
      }
    }
  }

  .backdrop-input {
    color: $color-white !important;
    width: 600px;
    max-width: 100%;

    .css-1wsxkze-MuiInputBase-root-MuiInput-root:before {
      border-bottom: 1px solid $color-primary !important;
    }

    .css-1x51dt5-MuiInputBase-input-MuiInput-input {
      color: $color-white;
    }
  }
}

@media screen and (max-width: 767px) {
  .backdrop {
    .backdrop-input {
      width: 100%;
      max-width: 500px;
    }
  }
}