@import '../../../dt-template/index';

.our-services-section {
    background-color: $color-secondary-light;
    padding: 5rem 0;

    .our-services-grid {
        padding-top: 5rem;

        .service-1,
        .service-2,
        .service-3 {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .service-1 {
            background-image: url('../../../assets/PNGs/service-bg-1.png');
        }

        .service-2 {
            background-image: url('../../../assets/PNGs/service-bg-2.png');
        }
        
        .service-3 {
            background-image: url('../../../assets/PNGs/service-bg-3.png');
        }

        .our-services-image {
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            margin-bottom: 2rem;

            img {
                width: 100%;
                max-width: 40px;
                height: auto;
            }
        }
    }

}

@media screen and (max-width: 767px) {
    .our-services-section {
        .our-services-grid {
            .MuiGrid-item:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }
}