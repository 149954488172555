.profile{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 70%;
    margin: auto;
    margin-top: 50px;
}

.orders-table{

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.navbar{
    margin-top:20px;
}