@import '../../../dt-template/vars';

.testimonials-section {
    padding-top: 3% !important;
    background-image: url('../../../assets/PNGs/testimonial-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);

    .testimonials-container {
        height: 100%;
        width: 100%;

        .white-testimonial-text {
            color: $color-white;
        }

        .light-testimonial-text {
            color: darken($color: $color-white, $amount: 25);
            font-weight: 600;
        }

        .upper-carousel-container {
            width: 100%;
            height: 75%;

            .upper-carousel {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    
        .lower-carousel-container {
            width: 100%;
            height: 25%;

            .company-logo:not(:last-child) {
                margin-right: 2rem;
            }

            .lower-carousel {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

@media screen and (max-width: 510px) {
    .testimonials-section {
        .testimonials-container {
            
            .MuiAvatar-root {
                width: 60px;
                height: 60px;
            }
            
            h4 {
                font-size: 20px;
            }

            h6 {
                font-size: 16px;
            }
        }
    }
}