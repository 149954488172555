@import '../../../dt-template/index';

.social-badge {
    border-radius: 50%;
    background-color: $color-primary-light;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .social-img {
        @include img-fluid(20px);
        color: $color-white;
    }

    &:hover {
        .social-img {
            color: $active-color;
        }
    }
}