@import '../../../dt-template/vars';

.contact-form-section {
    padding: 5rem 0;

    .badges-container {
        gap: 1rem;
    }
    
    .contact-detail {
        margin-bottom: 2rem;

        .icon-container {
            & > svg {
                font-size: 25px;
                color: $color-primary-light;
                margin-right: 1rem;
            }
        }
    }

    .MuiContainer-root {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .MuiGrid-root {
        display: block;
        max-width: 700px;
    }

    .textfield-width-control {
        width: 48%;
    }

    .text-area {
        margin-top: 1rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.4375em;
        display: inline-flex;
        align-items: center;
        position: relative;
        border-radius: 4px;
        padding: 8.5px 14px;
        border-color: rgba(0, 0, 0, 0.23);
        border-style: solid;
        border-width: 1px;
    }

    .text-area::placeholder {
        color: #bcbcbc;
    }

    .text-area:focus { 
        outline-color: #af6464;
    }

    .text-area:hover {
        border-color: #000000;
    }
}