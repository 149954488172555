@import '../../../dt-template/vars';

.card-item {
    border: 1px solid #ffffff;
    transition: border 0.3s ease-in;

    .strike {
        position: relative;
        display: inline-block;
    }

    .strike::before {
        content: '';
        border-bottom: 1px solid darken($active-color, $amount: 20);
        width: 100%;
        position: absolute;
        right: 0;
        top: 50%;
    }
    
    .action-buttons {
        display: none;
        position: absolute;
        bottom: 20px;
        animation: fadeTop 0.3s ease-in;
    }

    .card-image {
        position: relative;
    }

    &:hover {
        border: 1px solid $active-color;

        .action-buttons {
            display: flex;
            justify-content: center;
        }
    }
}

@keyframes fadeTop {
    from {
        bottom: 0; 
        opacity: 0;
    }

    to {
        bottom: 20px; 
        opacity: 1;
    }
}