@import '../../../dt-template/vars';

.action-buttons {
    width: 100%;

    .action-button:not(:last-child) {
        margin-right: 5px;
    }

    .action-button {
        border: 1px solid $active-color;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        & > svg {
            font-size: 18px;
            color: $active-color;
        }

        &:hover {
            background-color: $active-color;
            
            & > svg {
                color: $color-white;
            }
        }
    }
}