@import '../../../dt-template/vars';

.mini-card-item {
    cursor: pointer;
    transition: top ease 0.5s;
    position: relative;
    top: 0;

    &:hover {
        top: -6px;
    }

    .strike {
        position: relative;
        display: inline-block;
    }

    .strike::before {
        content: '';
        border-bottom: 1px solid darken($active-color, $amount: 20);
        width: 100%;
        position: absolute;
        right: 0;
        top: 50%;
    }

    .mini-product-image-container {
        padding: 4px;
        border: 1px solid lighten($color-primary-light, 15);

        .mini-product-image {
            height: 100px;
            width: 80px;
        }
    }

    .mini-product-details {
        margin-left: 0.5rem;
    }
}
