@import '../../../../dt-template/vars';

.myha-menu-content {
    width: 100%;
    height: 300px;
    display: flex;

    .menu-items-side {
        padding-top: 20px;
        width: 30%;
        background-color: #f1f1f1;
    }

    .active-el {
        background-color: $color-white;
        border: 1px solid #dfdfdf;
        border-right: none;
        border-left: none;
    }

    .css-lv47sw-MuiButtonBase-root-MuiMenuItem-root {
        height: 50px;
    }

    .css-lv47sw-MuiButtonBase-root-MuiMenuItem-root:hover {
        background-color: $color-white;
        border: 1px solid #dfdfdf;
        border-right: none;
        border-left: none;
    }

    .content-side {
        width: 70%;
        padding: 1rem;
    }
}