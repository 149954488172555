@import '../../../../dt-template/index';

.team-social-badges {
    margin-top: 1.5rem;
    gap: 0.5rem;

    .team-social-badge {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-color: lighten($color-primary-light, 25);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .team-social-img {
            @include img-fluid(15px);
            color: $color-primary;
        }
    
        &:hover {
            .team-social-img {
                color: $active-color;
            }
        }
    }
}