.title-flora {
    position: relative;

    .typo {
        z-index: 998;
        opacity: 1;
    }

    .flora {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.3;
        z-index: 0;
    }
}