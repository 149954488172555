@import '../../../dt-template/vars';

.product-text-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 15px;
    width: 50px;
    height: 23px;
    background-color: $active-color;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-white;
    z-index: 9;
    font-weight: 700;
    line-height: 14px;
    font-size: 10px;
}