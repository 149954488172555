.breadcrumbs {
    padding: 5rem 0;
    width: 100%;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url('../../assets/PNGs/breadcrumbs-bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 2000px rgba(245, 240, 240, 0.5);
}