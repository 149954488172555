// Colors
$color-primary: #696969 !default;
$color-primary-light: #ababab !default;
$active-color: #af6464 !default;
$color-secondary: #1a82c3 !default;
$color-secondary-light: #fcf5f6 !default;
$color-success: $color-primary !default;
// $color-success: #67c23a !default;
$color-warning: #eb9e05 !default;
$color-danger: #fa5555 !default;
$color-info: #878d99 !default;
$color-white: #fff !default;
$color-lightgray: #d3d3d3 !default;

// Social colors

$color-instagram: #e4405f;
$color-twitter: #55acee;
$color-pinterest: #bd081c;
$color-facebook: #3b5999;
$color-youtube: #cd201f;
