@import "../../../../dt-template/vars";

.product-item {
  .product-container {
    margin-bottom: 40px;
  }

  .actions-container {
    margin-top: 1rem;
  }

  .guide-element {
    margin-right: 15px;
  }

  .add-to-cart-button {
    height: 48px;
    border-radius: 40px;
  }

  .add-to-favourite-icon {
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e5e5e5;
    border-radius: 50%;

    &:hover {
      cursor: pointer;

      & > svg {
        color: $active-color;
      }
    }

    & > svg {
      color: $color-primary;
    }
  }

  .button-cart {
    height: 48px;
    font-weight: 700;
    background: none;
    border: none;
  }

  .quantity {
    width: 100px;
    color: $color-primary;
    height: 48px;
    position: relative;
    border: 1px solid #e5e5e5;
    border-radius: 40px;
  }

  .input-button-plus {
    position: relative;
    right: 4px;
    cursor: pointer;
    color: $color-primary;
  }

  .input-button-minus {
    position: relative;
    left: 4px;
    cursor: pointer;
    color: $color-primary;
  }

  .tag-name:hover,
  .category-title:hover {
    color: $active-color;
    cursor: pointer;
  }

  .share-icon {
    color: $color-primary;
    font-size: 16px;

    &:hover {
      color: $active-color;
      cursor: pointer;
    }
  }

  .strike {
    position: relative;
    display: inline-block;
  }

  .strike::before {
    content: "";
    border-bottom: 2px solid $color-primary;
    width: 100%;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .product-image {
    width: 100%;
    height: auto;
    max-height: 571px;
    max-width: 600px;
  }
}

@media screen and (max-width: 767px) {
  .product-item {
    .responsive-grid {
      width: 100%;
    }
  }
}

@media screen and (max-width: 460px) {
  .shop-products-list {
    .header-filters {
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 4rem;
    }
  }
}

@media screen and (max-width: 356px) {
  .product-item {
    .actions-container {
      flex-wrap: wrap;
      gap: 1rem;

      .add-to-cart-button {
        order: 2;
      }

      .quantity {
        order: 1;
      }

      .add-to-favourite-icon {
        order: 3;
      }
    }
  }
}
