@import '../../../dt-template/index';

.welcome-section {
    padding: 5rem 0;

    .welcome-grid {
        padding-top: 5rem;

        .welcome-image {
            width: 180px;
            height: 180px;
            margin: auto;
            margin-bottom: 1rem;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }

}

@media screen and (max-width: 767px) {
    .welcome-section {
        .welcome-grid {
            .MuiGrid-item:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }
}