#legal-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 0.5rem;

    a{
        text-decoration: none;
        font-size: 0.8rem;
        color: black;

    }
    a:nth-child(2){
        text-decoration: none;
        padding: 1rem;
        font-size: 0.8rem;
    }
}