@import '../../dt-template/vars';
@import '../../dt-template/index';

.header {
    .icon-box {
        position: relative;

        & > svg {
            font-size: 1.2rem;
            color: $color-primary;
            cursor: pointer;

            &:hover {
                color: $active-color;
            }
        }
        
    }

    .custom-breakpoint {
        .icons-container {
            & > svg {
                padding-right: 1rem;
                color: $color-primary-light;
                cursor: pointer;

                &:hover {
                    color: $active-color;
                }
            }
        }
        .header-link {
            cursor: pointer;

            &:hover {
                color: $active-color;
            }
        }
    }
    .logo-img {
        cursor: pointer;
        @include img-fluid(270px);

        @media screen and (max-width: 400px) {
            width: 60%;
        }
    }
}

.drawer-header {
    height: 25px;
    background: #000000;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.drawer-actions {
    padding: 30px;

    & > div {
        display: flex;
        justify-content: center;
    }

    .margin-icon {
        margin-right: 20px;
    }

    .cursor-pointer {
        cursor: pointer;

        &:hover {
            color: $active-color;
        }
    }

    svg {
        font-size: 20px;
        color: $color-primary;
    }
}

@media screen and (max-width: 991px) {
    .header {
        .custom-breakpoint {
            flex-direction: column;
            padding-right: 0;
            padding-left: 0;

            .icons-container {
                margin-bottom: 1rem;

                & > svg {
                    padding-right: 2rem;
                }

                & > svg:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}
