@import '../../dt-template/vars';

.app-bar {
    .icons-container {
        .icon-box {
            position: relative;

            & > svg {
                font-size: 1.2rem;
                color: $color-primary;
                cursor: pointer;
    
                &:hover {
                    color: $active-color;
                }
            }
            
        }
    }
    .nav-item {
        cursor: pointer;

        &:hover {
            h6,
            .font-size-arrow {
                color: $active-color;
            }
        }
    }
}