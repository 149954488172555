@import '../../../dt-template/index';

.our-team-section {
    background-color: $color-white;
    padding: 5rem 0;

    .our-team-grid {
        padding-top: 5rem;

        .our-team-image {
            width: 180px;
            height: 180px;
            margin: auto;
            margin-bottom: 1rem;
            background-image: url('../../../assets/PNGs/our-team.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 50%;
        }
    }

}

@media screen and (max-width: 767px) {
    .our-team-section {
        .our-team-grid {
            .MuiGrid-item:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }
}