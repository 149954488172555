@import './dt-template/vars';
@import './dt-template//index';

.app {
    .MuiAppBar-root {
        z-index: 999;
    }

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background: #ffffff;
        z-index: 9999;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .MuiBreadcrumbs-ol {
        justify-content: center;
    }
}

.MuiBox-root.css-mgur8 {
    z-index: 99999;
}

@media screen and (max-width: 800px) {
    .react-responsive-modal-closeButton {
        right: 4px !important;
    }
}